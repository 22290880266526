:root {
  --text-color: #333;
  --text-color-light: #666;
  --bg-color: #fff;
  --bg-color-light: #f5f5f5;
  --border-color: #ddd;
  --accent-color: #14b582;
  --shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  --border-radius: 8px;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: var(--bg-color-light);
  color: var(--text-color);
}

.container1 {
  width: 80%;
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

h1,
h2,
h3 {
  color: var(--text-color);
  text-align: center;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5em;
  margin-top: 20px;
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 5px;
}

h3 {
  font-size: 1.2em;
  color: var(--text-color-light);
  margin-top: 0;
}

p {
  margin: 10px 0;
  font-size: 1em;
  color: var(--text-color-light);
  text-align: justify;
}

.elements {
  margin-top: 20px;
}

.element {
  margin-bottom: 20px;
  padding: 10px;
  border-left: 5px solid var(--accent-color);
  background-color: var(--bg-color-light);
  border-radius: 5px;
}

.image-container {
  text-align: center;
  margin-bottom: 20px;
}

.image-container img,
.container1 img {
  max-width: 100%;
  height: auto;
}

span {
  font-weight: bold;
}

#hh {
  text-decoration: underline;
}

.logo {
  display: inline;
}

.logo img {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  vertical-align: middle;
  margin-top: 2px;
}

@media (max-width: 768px) {
  .container1 {
    width: 95%;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.3em;
  }
}

.about {
  padding: 30px;
  max-width: 100%;
  min-width: 90%;
  animation: fadeIn 1.5s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}