/* FAQ.css */

.faq-container {
  /* background-color: #fff; */
min-width: 90%;
max-width: 90%;
  margin: 0 auto;
  /* padding: 20px; */
  font-family: Arial, sans-serif;
  color: #333;
}

.faq-container h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.faq-list {
  margin-top: 20px;
}

.faq-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.faq-question {
  background-color: #f4f4f4;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question h2 {
  margin: 0;
  font-size: 1.2rem;
}

.toggle-icon {
  font-size: 1.5rem;
}

.faq-answer {
  padding: 15px;
  border-top: 1px solid #ddd;
}

.faq-answer p {
  margin: 0;
}

/* Responsive Design */

@media (max-width: 768px) {
  .faq-container {
      padding: 15px;
  }

  h1 {
      font-size: 1.8rem;
  }

  .faq-question h2 {
      font-size: 1rem;
  }

  .toggle-icon {
      font-size: 1.2rem;
  }
}
