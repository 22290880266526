.not-found-container {
    /* background-image: url('C:\Users\PARI\Downloads\Social-Media-Links-main\Story\exotic veda\exoticveda\src\Routes\notfound.png'); */
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: contain; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
  }
  
  .not-found-content {
    text-align: center;
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .not-found-title {
    font-size: 8rem;
    color: #ff6b6b;
    margin: 0;
  }
  
  .not-found-text {
    font-size: 1.5rem;
    color: #4a4a4a;
    margin: 1rem 0 2rem;
  }
  
 
  
  .not-found-link:hover {
    background-color: #45b7aa;
  }
  
  @media (max-width: 480px) {
    .not-found-title {
      font-size: 6rem;
    }
  
    .not-found-text {
      font-size: 1.2rem;
    }
  }