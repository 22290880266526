@import url('https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap');

.Product-Page-Contanier {
  text-align: justify;
  /* margin-top: 1rem; */

}

.img-aloe {
  display: flex;

}



.right-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  width: 18%;
  height: 250px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;

}


.right-container button {
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;

}

.right-container button:hover {
  background-color: #0056b3;

}


.Aloevera-Description ul {
  list-style-type: none;

  padding: 0;
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

.Aloevera-Description ul li {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.Aloevera-Description ul li:hover {
  transform: translateY(-5px);
}


.Aloevera-Description ul li:first-child {
  font-weight: bold;
  background-color: #dff0d8;
  border-color: #c3e6cb;
  color: #3c763d;
}




.btn2 {
  background-color: #311e1e;
  color: wheat;
  margin-top: 20px;
  font-size: 20px;
  padding: 10px 80px;
  border-radius: 40px;
}

.btn2 a {
  color: wheat;

  font-size: 20px;

}

.btn2:hover {
  background-color: #f0f0f0;
  color: black;
  cursor: pointer;

}

.btn2:hover a {

  color: black;

}


.product-detail-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.recipes-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  justify-content: center;
}



.fo {


  font-family: "Alice", serif;
  font-weight: 400;
  font-style: normal;

  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.recipe-card {


  font-family: "Alice", serif;
  font-weight: 400;
  font-style: normal;

  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.recipe-image {
  flex: 1;
  margin-right: 20px;
}

.recipe-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.recipe-details {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.recipe-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.recipe-card h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #666;
}

.ingredients,
.instructions {
  margin-bottom: 10px;
}

.ingredients ul,
.instructions ul {
  padding-left: 20px;
}

.recipe-card p {
  font-size: 1rem;
  color: #555;
  margin-top: 10px;
}







/* ============HAir tips =========== */

.hair-care-detail-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.haircare-recipes-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.haircare-recipe-card {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.haircare-recipe-image {
  flex: 1;
  margin-right: 20px;
}

.haircare-recipe-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.haircare-recipe-details {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.haircare-recipe-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.haircare-recipe-card h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #666;
}

.ingredients,
.instructions {
  margin-bottom: 10px;
}

.ingredients ul,
.instructions ul {
  padding-left: 20px;
}

.haircare-recipe-card p {
  font-size: 1rem;
  color: #555;
  margin-top: 10px;
}



/* ======tab switch  start -======== */

/* Tab Container */
.tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Set columns for the first two buttons */
  gap: 10px;
}

/* Tab Button */
.tabs button {

  padding: 20px 20px;
  /* border: none; */
  cursor: pointer;
  /* background-color: #f0f0f0; */
  background-color: #0056b3;
  color: white;
  border: none;
  font-size: 1rem;
  margin: 0 10px;
  border-radius: 10px;

}

/* Active Tab Button */
.tabs button.active {
  background-color: #0056b3;
  color: white;
  /* border-radius: 5px 5px 0 0; */
  transition: background-color 0.3s ease, color 0.3s ease, border-bottom 0.3s ease;

}

/* Hover Effect */
.tabs button:hover {
  background-color: #0056b3;
  color: white;
  border: none;
}









/* ==================discalair cssss-==================== */

.disclaimer {
  text-align: center;
  font-size: 20px;
  color: white;
}

.disclaimer span {
  color: black;
  font-weight: 900;
  font-size: 22px;

}

/* ===================end disclimer  ==================== */


/* ===========receipe card  ingredent  ul for lemin shikak =================== */
.recipe-card .recipe-details .ingredients ul,
.instructions ul {
  padding: 30px;
  list-style: disc;
  text-align: start;
}

.recipe-card .recipe-details .ingredients1 ul,
.instructions1 ul {
  padding: 30px;
  list-style: disc;
  background-color: rgba(246, 203, 160, 255);
  text-align: start;
  color: black;
}

.recipe-card .recipe-details .ingredients2 ul,
.instructions2 ul {
  padding: 30px;
  list-style: disc;
  background-color: rgba(181, 207, 116, 255);
  text-align: start;
  color: black;
}

/* ===================end===================== */



/* =======AJmun seed recipie  ocntain=============== */
.jamun-seed-powder-container .rr ul {
  background-color: rgba(75, 56, 104, 255);
  font-family: "Alice", serif;
  /* list-style: none; */
  display: grid;
  text-align: justify;
  grid-template-columns: 1fr;
  color: white;
  padding: 20px;


}

.jamun-seed-powder-container .recipe-card ul {
  background-color: rgba(75, 56, 104, 255);
  color: white;
  border-radius: 10px;
}


.float{
  gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr ;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
@media (max-width: 768px) {
  .float{

    grid-template-columns: 1fr ;

  }
}