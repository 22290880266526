/* @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');

body {

  font-family: serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
body button{
  font-family: serif;

}

body img[data-loaded="true"] {
  opacity: 1;
  animation: fadeIn 0.5s ease forwards;
}
@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row {
  font-family: "Alice", serif;
  font-weight: 400;
  font-style: normal;

}

ul {
  text-align: justify;
  font-family: "Alice", serif;
}


.rrrr h1 {
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(237, 231, 231);

}

.card img {
  max-width: 100%;
  min-width: 100%;
}
.card {
  width: 100%;
  margin: 0 auto;
  border: none;
  /* Remove border for a cleaner look */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* General styling for mobile view */


