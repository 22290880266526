.review-page-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-page-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.review-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.input-name,
.input-review {
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
}

.input-review {
  height: 100px;
  resize: none;
}

.submit-button {
  padding: 0.75rem;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.reviews-container {
  text-align: left;
  margin-top: 2rem;
}

.review-card {
  background-color: #ffffff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.review-card h4 {
  margin: 0 0 0.5rem;
  color: #007bff;
}

.review-card p {
  margin: 0;
  color: #555;
}

/* Responsive styling */
@media (max-width: 600px) {
  .review-page-container {
    padding: 1rem;
  }

  .review-page-title {
    font-size: 1.8rem;
  }

  .submit-button,
  .input-name,
  .input-review {
    font-size: 0.9rem;
  }

  .review-card {
    padding: 0.75rem;
  }
}